import { createClient, configureChains, defaultChains, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { SessionProvider } from 'next-auth/react';
import React, { useState, Component, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes, useHistory, useParams, Navigate } from 'react-router-dom';

import logo from './logo.svg';
// import small_logo from './res/small_logo.png';
// import res_logo from './res/logo.png';
// import res_logo1 from './res/defaultmember.png';
// import res_dashboard from './res/dashboard.png';
// import res_portfolio from './res/portfolio.png';
// import res_news from './res/news.png';
// import res_events from './res/events.png';
// import res_documents from './res/documents.png';
// import res_demograph from './res/demograph.png';
// import res_logout from './res/logout.png';
// import res_member from './res/member.png';
import s from './App.module.css';

// import './App.css';
// import { ethers } from "https://cdn.skypack.dev/ethers";
import $ from 'jquery';



//import Home from './pages/Home';
// import Home2 from './pages/Home2';
// import Explore from './pages/Explore';
// import Feeds from './pages/Feeds';
//import Newsfeed from './pages/newsfeed';
//import Post from './pages/post';
//import LoginMetaMask from './pages/LoginMetaMask';
// import Page from './pages/page';
// import Frontpage from './pages/frontpage'
//import Signin from './pages/signin'
// import SignupWeb3Auth from './pages/SignupWeb3Auth'
//import Walleteditor from './pages/walleteditor'
//import Walletlist from './pages/walletlist'
//import Pageeditor from './pages/pageeditor'
//import Accountregistration from './pages/accountregistration'
//import Linkmanager from './pages/linkmanager'
//import NFTeditor from './pages/nfteditor'
//import Forgotpw from './pages/forgotpw'




// mongoDB editor pages
// import Navbar from "./components/navbar";
// import RecordList from "./components/recordList";
// import Edit from "./components/edit";
// import Create from "./components/create";

// import { MoralisProvider } from "react-moralis";
//import { ChakraProvider, extendTheme } from "@chakra-ui/react";

// integrate login & cookies
import { useCookies } from 'react-cookie';
// import Register from './pages/Register';
// import Login from './pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';

import * as Commonlib from './api/commonlib.js';

import { injectIntl, useIntl, FormattedMessage } from 'react-intl';

import { IntlProvider } from 'react-intl';
import zh_HK from './locales/zh_HK.json';
import zh_CN from './locales/zh_CN.json';
import en_US from './locales/en_US.json';

const locale = navigator.language;
let localization;
// if (locale === "en_US") {
//   lang = en_US;
// } else if (locale === "zh_HK") {
//   lang = zh_HK;
// } else if (locale === "zh_CN") {
//   lang = zh_CN;
// }

// Import lazy loading
const Explore = React.lazy(() => import('./pages/Explore'));
const Game = React.lazy(() => import('./pages/game'));
const ChatDoll = React.lazy(() => import('./pages/chatdoll'));
const SchoolBot = React.lazy(() => import('./pages/schoolbot'));
const Library = React.lazy(() => import('./pages/library'));
const Medical = React.lazy(() => import('./pages/medical'));
const Research = React.lazy(() => import('./pages/research'));
const School = React.lazy(() => import('./pages/school'));
const GroupDiscussion = React.lazy(() => import('./pages/groupdiscussion'));
const GroupDiscussion_Canossa = React.lazy(() => import('./pages/groupdiscussion_Canossa'));
const SimpleChatBot = React.lazy(() => import('./pages/simplechatbot'));
const Bot = React.lazy(() => import('./pages/bot'));//bot_testcase
const BotOffline = React.lazy(() => import('./pages/bot_offline'));//bot_testcase
const CheckBot = React.lazy(() => import('./pages/checkbot'));//bot_testcase
const SchoolWorkManager = React.lazy(() => import('./pages/schoolworkmanager'));//bot_testcase

const Flashcardmanager = React.lazy(() => import('./pages/flashcardmanager'));//bot_testcase

const MCManager = React.lazy(() => import('./pages/mcmanager'));//bot_testcase
const SchoolWorkIngest = React.lazy(() => import('./pages/schoolworkingest'));
const Highresfilestraining = React.lazy(() => import('./pages/highresfilestraining'));
const Casestudygen = React.lazy(() => import('./pages/casestudygen'));
const SchoolWorkAdvViewer = React.lazy(() => import('./pages/schoolworkadvviewer'));
const SchoolWorkReviewer = React.lazy(() => import('./pages/schoolworkreviewer'));
const SchoolworkAdvReview = React.lazy(() => import('./pages/schoolworkadvreview'));



const MCAdvViewer = React.lazy(() => import('./pages/mcadvviewer'));//bot_testcase
const SchoolWorkViewer = React.lazy(() => import('./pages/schoolworkviewer'));//bot_testcase
const MySchoolWork = React.lazy(() => import('./pages/myschoolwork'));//bot_testcase
const SchoolWorkSubmitted = React.lazy(() => import('./pages/myschoolworksubmitted'));//bot_testcase
const Schoolclassviewer = React.lazy(() => import('./pages/schoolclassviewer'));//bot_testcase
const DistributionManger = React.lazy(() => import('./pages/distributionmanger'));//bot_testcase
const SchoolMemberViewer = React.lazy(() => import('./pages/schoolmemberviewer'));
const SchoolMemberManager = React.lazy(() => import('./pages/schoolmembermanager'));
const AdminPanel = React.lazy(() => import('./pages/adminpanel'));//bot_testcase
const PerformanceViewer = React.lazy(() => import('./pages/performanceviewer'));
const Flashcardadaptiveviewer = React.lazy(() => import('./pages/flashcardadaptiveviewer'));


const Chemqaeditor = React.lazy(() => import('./pages/chemqaeditor'));
const Incu = React.lazy(() => import('./pages/incu'));
const Login = React.lazy(() => import('./pages/Login'));
const Frontpage = React.lazy(() => import('./pages/frontpage'));
const Register = React.lazy(() => import('./pages/Register'));
const Flashcardportal = React.lazy(() => import('./pages/flashcardportal'));
const Flashcardlevels = React.lazy(() => import('./pages/flashcardlevels'));
const Portal = React.lazy(() => import('./pages/portal'));
const ChatHistory = React.lazy(() => import('./pages/chathistory'));
const BotDeployment = React.lazy(() => import('./pages/botdeployment'));
const SpeakingManager = React.lazy(() => import('./pages/speakingManager'));
const SpeakingRecords = React.lazy(() => import('./pages/speakingrecords'));
const MySpeakingRecords = React.lazy(() => import('./pages/myspeakingrecords_IPAD'));
const Slideaieditor = React.lazy(() => import('./pages/slideaieditor'));
const FAQMaker = React.lazy(() => import('./pages/faqmaker'));
const Schoolworkqaadveditor = React.lazy(() => import('./pages/schoolworkqaadveditor'));
const Schoolworkqaeditor = React.lazy(() => import('./pages/schoolworkqaeditor'));
const Questionanseditor = React.lazy(() => import('./pages/questionanseditor'));
const MCBot = React.lazy(() => import('./pages/mcbot'));
const McLang = React.lazy(() => import('./pages/mclang'));
const QuestionEditor = React.lazy(() => import('./pages/schoolworkquestionmaker'));
const Essaymarker = React.lazy(() => import('./pages/essaymarker'));
const Schoolworkmcadvmaker = React.lazy(() => import('./pages/schoolworkmcadvmaker'));


const Exercisemaker = React.lazy(() => import('./pages/exercisemaker'));
const SchoolworkAI = React.lazy(() => import('./pages/schoolworkbot'));
const SchoolworkadvAI = React.lazy(() => import('./pages/schoolworkadvbot'));
const SchoolworkSubmitted = React.lazy(() => import('./pages/schoolworkbotsubmitted'));
const MyAssignments = React.lazy(() => import('./pages/myassignments'));

const MyFlashCards = React.lazy(() => import('./pages/myflashcards'));

const Report = React.lazy(() => import('./pages/report'));
const Billing = React.lazy(() => import('./pages/billing'));
const Training = React.lazy(() => import('./pages/training'));
const Invoice = React.lazy(() => import('./pages/Invoice'));
const Training_info = React.lazy(() => import('./pages/training_info'));
const Bot_media = React.lazy(() => import('./pages/botmedia'));
const Training_lib = React.lazy(() => import('./pages/training_lib'));
const Training_study = React.lazy(() => import('./pages/training_study'));
const Training_conversation = React.lazy(() => import('./pages/training_conversation'));
const Training_roleplay = React.lazy(() => import('./pages/training_roleplay'));
const Changepw = React.lazy(() => import('./pages/changepw'));
const Resetpw = React.lazy(() => import('./pages/resetpw'));
const Forgotpw = React.lazy(() => import('./pages/forgotpw'));
const Getdbdata = React.lazy(() => import('./pages/getdbdata'));
const Accountregistration = React.lazy(() => import('./pages/accountregistration'));
const Navbar = React.lazy(() => import('./components/navbar'));
const LandingPage = React.lazy(() => import('./pages/landingpage'));
const LandingPageHK = React.lazy(() => import('./pages/landingpage_hk'));
const LandingPageTW = React.lazy(() => import('./pages/landingpage_tw'));
const LandingPageCN = React.lazy(() => import('./pages/landingpage_cn'));
const ErrorPage = React.lazy(() => import('./pages/errorpage'));
const Signin = React.lazy(() => import('./pages/signin'));
const User = React.lazy(() => import('./pages/user'));
const EssayBotTrainer = React.lazy(() => import('./pages/essaybottrainer'));
// const Mint = React.lazy(() => import('./pages/mint'));

// const Test = React.lazy(() => import('./pages/Test'));

const { provider, webSocketProvider } = configureChains(defaultChains, [publicProvider()]);

const client = createClient({
  provider,
  webSocketProvider,
  autoConnect: true,
});

var intl = "";


const defaultLocale = localStorage['locale'] ? localStorage['locale'] : en_US; // English is default locale if none is set
const localeList = [
  { name: 'English', code: 'en_US', lang: 'English' },
  { name: '中文（繁）', code: 'zh_HK', lang: 'Chinese' },
  { name: '中文（简）', code: 'zh_CN', lang: 'Chinese' }
];

// alert("App.js");
// const About = React.lazy(() => import('./pages/About'));

// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Link
// } from "react-router-dom";

// alert("App.js Hello world");

// const moralisAppId = "RMpczYfIv9w6gxuLFfG6i4IxEnO4CxGDwYoYYJ3Q";
// const moralisServerURL = "https://hklw0sykxryx.usemoralis.com:2053/server";

function handleTopBarClick() {
  // alert("handleTopBarClick");
  // //alert("handleOnClickMyWallets");
  window.location.href = '/mypage';
}

// function redirect() {
//   alert("");
//   var value = document.getElementById("pagesDropDown").value;
//   window.location.href = value;
// }
/*
 <Route
            path="/mypage/:id"
            element={
              <React.Suspense fallback={<>loading page...</>}>                

                <div className="welcome">
                <div className="cl_loginbar">
                  <button onClick={handleTopBarClick}><b><img className='cl_topbarimg' src={res_member} /><span>{cookies.username}</span></b></button>
                    <select name="pages" id="pagesDropDown" className={s.pagesdropdown} onChange={choice}>
                    </select>
                  <button className='cl_topbtn1' onClick={AppLogout}>{cookies.username != undefined ? 'Log out' : 'Sign in'}</button>
                  </div>
                  <Mypage cookies={ cookies }/>
                </div>


              </React.Suspense>
            }
          />
*/


function App() {
  intl = useIntl();

  var localeInCookie = Commonlib.readCookie("locale");
  if (localeInCookie == undefined) {
    localeInCookie = "en_US"
  }

  // let lang;
  if (localeInCookie === "en_US") {
    localization = en_US;
  } else if (localeInCookie === "zh_HK") {
    localization = zh_HK;
  } else if (localeInCookie === "zh_CN") {
    localization = zh_CN;
  }
  // else {
  //  localization = en_US; 
  // }

  // console.log(localization);
  React.useEffect(() => {
    var select = $("#localesDropDown")[0];
    // alert(pageid);
    if (select) {
      for (var i = 0; i < select.options.length; i++) {
        // if(select.options[i].value == window.location.origin + '/mypage/' + xid ){

        if (select.options[i].value == localeInCookie) {
          select.options[i].selected = true;
          // TODO: update cookie, mypagexid = xid
          // setCookie('pagexid', xid, { path: '/' });
        }
      }
    }

  });
  /*
     <Route 
              path="/game" 
              element={
                <React.Suspense fallback={<>loading game...</>}>
                  <div className="welcome">
                  <div className="cl_loginbar">
                    <b><img className='cl_topbarimg' src={res_member}/><span>{cookies.username}</span></b>
                    <button className='cl_topbtn1' onClick={AppLogout}>{cookies.username != undefined blue is  'Log out' : 'Sign in'}</button>
                    </div>
                    <Game isLoggedIn={ cookies.username != undefined }/>
                  </div>
                </React.Suspense>
              }
            />
  */


  const [currentLocale, setCurrentLocale] = useState(localization);

  const onChangeLanguage = (e) => {
    // alert("change locale: " + e.target.value);
    // setCookie('locale', selectedLocale, { path: '/' });

    // TODO: reload
    // window.location.reload();

    const selectedLocale = e.target.value;

    setCookie('locale', selectedLocale, { path: '/' });

    window.location.reload();


    // // TODO: add condition here
    // if (selectedLocale === "en_US") {
    //   setCurrentLocale(en_US);
    // } else if (selectedLocale === "zh_HK") {
    //   setCurrentLocale(zh_HK);
    // } else if (selectedLocale === "zh_CN") {
    //   setCurrentLocale(zh_CN);
    // }


    // setCurrentLocale(zh_HK);
    localStorage.setItem('locale', selectedLocale)
  }

  // alert(intl.formatMessage({id: 'mypage/CREATE_NFT'}));
  // const [id, setId] = useState();
  // const history = useHistory();

  // const handleProceed = (e) => {
  //   id && history.push(generatePath("/products/:id", { id }));
  // };


  const adminUser = {
    email: "admin@admin.com",
    password: "admin123"
  }

  function choice(e) {
    // alert(123);
    // alert(e);
    // console.log(e.target.value);
    var pagexid = e.target.value;
    setCookie('pagexid', pagexid, { path: '/' });
    // TODO: set cookie

    window.location.href = '/mypage';


    // window.location.href = '/mypage';
    // alert(select.options[select.selectedIndex].text);
  }


  const [user, setUser] = useState({ id: "", name: "", email: "", isLoggedIn: false });
  const [error, setError] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(['username']);


  // alert((user.email != "") || (cookies.username != undefined));
  // alert(cookies.username != undefined);
  // alert(user.email != "");

  // pass in function to component for using later

  const AppLogin = details => {
    // alert("App.js: called appLogin");

    // TODO: details.status:
    //   LOGGED_IN
    //   NO_SERVER_RESPONSE
    //   MISSING_USERNAME_OR_PASSWORD
    //   UNAUTHORIZED
    //   LOGIN_FAILED

    // if (details.email == adminUser.email && details.password == adminUser.password) {
    console.log(details);

    if (details.status == "LOGGED_IN") {
      // alert("AppLogin: LOGGED IN");
      // alert(details);
      // alert(details);     
      setUser({
        // id: details.id,
        name: details.name,
        email: details.email,
        isLoggedIn: true
      });
      // TODO: set cookie
      // document.cookie = "username=John Smith;";
      // setCookie('id', details.id, { path: '/' });
      setCookie('memberid', details.memberid, { path: '/' });
      setCookie('pagexid', details.pagexid, { path: '/' });
      setCookie('nickname', details.nickname, { path: '/' });
      setCookie('username', details.name.toLowerCase(), { path: '/' });
      setCookie('org', details.org, { path: '/' });
      setCookie('cids', details.cids, { path: '/' });
      setCookie('dept', details.dept, { path: '/' });
      setCookie('oid', details.oid, { path: '/' });
      console.log("setup cookie:" + details.nickname);
      // removeCookie('name', { path: '/' });
    } else {
      alert("App.js: Details do not match");
      console.log("Details do not match");
      setError(details.status);
    }
  }

  const AppLogout = () => {
    console.log("Logout");
    setUser({ name: "", email: "", isLoggedIn: false });
    // TODO: delete cookie
    // removeCookie('id', { path: '/' });
    removeCookie('memberid', { path: '/' });
    removeCookie('username', { path: '/' });
    removeCookie('pagexid', { path: '/' });
    removeCookie('nickname', { path: '/' });
    removeCookie('botid', { path: '/' });
    removeCookie('org', { path: '/' });
    removeCookie('cids', { path: '/' });
    window.location.href = '/';
  }

  // var addanewpage = async (e) => {


  // let text = "Create a new page?";
  // let response;
  //   if (window.confirm(text) == true) {
  //      response = await axios.post("/createpage",
  //     JSON.stringify({ memberid }),
  //     {
  //         headers: { 'Content-Type': 'application/json' },
  //         withCredentials: true
  //     }

  //   );
  //     console.log("-----------add new page--------------");
  //      if(response.data.newpid)
  //      {
  //       window.location.href = "/mypage/"+response.data.newpid;

  //      }
  //   } 
  // }


  // const handleLogoutReturnFrontpage = () => {
  //   //do something
  //   AppLogout();
  //   //do something
  //   window.location.href = '/frontpage';

  // }

  // <div className="welcome">
  // <div className="cl_loginbar">
  // <button onClick={handleTopBarClick}><b><img className='cl_topbarimg' src={res_member} /><span>{cookies.username}</span></b></button>
  <button className='cl_topbtn1' onClick={AppLogout}>{cookies.username != undefined ? 'Log out' : 'Sign in'}</button>
  // </div>
  // <Mypage cookies={ cookies }/>
  // </div>

  console.log(currentLocale);


  return (
    <IntlProvider locale={locale} messages={currentLocale}>

      <div className="wrapper">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <React.Suspense fallback={<></>}>
                  <LandingPage />
                </React.Suspense>


              }
            />

            <Route
              path="/hk"
              element={
                <React.Suspense fallback={<>loading homepage...</>}>
                  <LandingPageHK />
                </React.Suspense>
              }
            />

            <Route
              path="/tw"
              element={
                <React.Suspense fallback={<>loading homepage...</>}>
                  <LandingPageTW />
                </React.Suspense>
              }
            />

            <Route
              path="/cn"
              element={
                <React.Suspense fallback={<>loading homepage...</>}>
                  <LandingPageCN />
                </React.Suspense>
              }
            />

            <Route
              path="/demo/library"
              element={
                <React.Suspense fallback={<>loading library...</>}>
                  <div className="welcome">
                    <div className="cl_loginbar">
                      <b><img className='cl_topbarimg' /><span>{cookies.username}</span></b>
                    </div>
                    <Library cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />

            <Route
              path="/demo/medical"
              element={
                <React.Suspense fallback={<>loading medical...</>}>
                  <div className="welcome">
                    <div className="cl_loginbar">
                      <b><img className='cl_topbarimg' /><span></span></b>
                    </div>
                    <Medical cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />

            <Route
              path="/demo/research"
              element={
                <React.Suspense fallback={<>loading research...</>}>
                  <div className="welcome">
                    <div className="cl_loginbar">
                      <b><img className='cl_topbarimg' /><span></span></b>
                    </div>
                    <Research cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />

            <Route
              path="/demo/school"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">
                    <div className="cl_loginbar">
                      <b><img className='cl_topbarimg' /><span>{cookies.username}</span></b>
                    </div>
                    <School cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />

            <Route
              path="/WriteBot/:memberid_from_url/:botid_from_url/:version_from_url"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <CheckBot cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/bot/:memberid_from_url/:botid_from_url/:version_from_url"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <Bot cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />


            <Route
              path="/bot_offline/:memberid_from_url/:botid_from_url/:version_from_url"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <BotOffline cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />


            <Route
              path="/checkbot"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <CheckBot cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />

            <Route
              path="/mclang/:botid_from_url"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <McLang cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/mclang/:botid_from_url/:distribution_id"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <McLang cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/mclang/:botid_from_url/:distribution_id/:flashcard_id/:n"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <McLang cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/mcbot/:botid_from_url"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <MCBot cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/mcbot/:botid_from_url/:distribution_id"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <MCBot cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/mcsheet/:botid_from_url"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <MCBot cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />

            <Route
              path="/schoolworkadvai/:botid_from_url"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <SchoolworkadvAI cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/qasheet/:botid_from_url/:distribution_id"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <SchoolworkadvAI cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/qasheet/:botid_from_url"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <SchoolworkadvAI cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkadvai/:botid_from_url/:distribution_id"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <SchoolworkadvAI cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />

            <Route
              path="/schoolworkai/:botid_from_url"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <SchoolworkAI cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkai/:botid_from_url/:distribution_id"
              element={
                <React.Suspense fallback={<>loading school...</>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <SchoolworkAI cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworksubmitted/:botname"
              element={
                <React.Suspense fallback={<></>}>
                  <div className="welcome">

                    <b><img className='cl_topbarimg' /></b>

                    <SchoolworkSubmitted cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />


            <Route
              path="/demo/incu"
              element={
                <React.Suspense fallback={<>loading incu...</>}>
                  <div className="welcome">
                    <div className="cl_loginbar">
                      <b><img className='cl_topbarimg' /><span>{cookies.username}</span></b>
                    </div>
                    <Incu cookies={cookies} />
                  </div>
                </React.Suspense>
              }
            />

            <Route
              path="/demo/chatdoll"
              element={
                <React.Suspense fallback={<>loading chatdoll...</>}>
                  <div className="welcome">
                    <div className="cl_loginbar">
                      <b><img className='cl_topbarimg' /><span>{cookies.username}</span></b>
                      <button className='cl_topbtn1' onClick={AppLogout}>{cookies.username != undefined ? 'Log out' : 'Sign in'}</button>
                    </div>
                    <ChatDoll cookies={cookies} />
                    <Explore isLoggedIn={cookies.username != undefined} />
                  </div>
                </React.Suspense>
              }
            />
            <Route
              path="/explore"
              element={
                <React.Suspense fallback={<>loading explore...</>}>
                  <Explore />
                </React.Suspense>
              }
            />
          
          <Route
              path="/flashcardlevels/:xid/:n/:did"
              element={
                <React.Suspense fallback={<>loading flash card portal...</>}>
                  <Flashcardlevels AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/flashcardlevels/:xid/:n/"
              element={
                <React.Suspense fallback={<>loading flash card portal...</>}>
                  <Flashcardlevels AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
          <Route
              path="/flashcardportal/:xid"
              element={
                <React.Suspense fallback={<>loading flash card portal...</>}>
                  <Flashcardportal AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/flashcardportal/:xid/:did"
              element={
                <React.Suspense fallback={<>loading flash card portal...</>}>
                  <Flashcardportal AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/portal"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Portal AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            {/*          
          <Route 
            path="/schoolbotai/portal" 
            element={
              <React.Suspense fallback={<>loading portal...</>}>
                <Portal AppLogout={AppLogout} cookies={ cookies } isLoggedIn={ cookies.username != undefined } botName="schoolbotai"/>
              </React.Suspense>
            } 
          />

*/}

            <Route
              path="/questioneditor/"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <QuestionEditor editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/schoolworkmcadvmaker/"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Schoolworkmcadvmaker editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkmcadvmaker/:xid"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Schoolworkmcadvmaker editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/flashcardmcadvmaker"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Schoolworkmcadvmaker editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} contentType='flashCard'/>
                </React.Suspense>
              }
            />
            <Route
              path="/flashcardmcadvmaker/:xid"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Schoolworkmcadvmaker editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} contentType='flashCard' />
                </React.Suspense>
              }
            />
            <Route
              path="/essaymarker/"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Essaymarker editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined}  />
                </React.Suspense>
              }
            />

            <Route
              path="/essaybottrainer"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <EssayBotTrainer editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/groupdiscussion/canossacollege"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <GroupDiscussion_Canossa editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/groupdiscussion"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <GroupDiscussion editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/simplechatbot"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <SimpleChatBot editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/questionsetmaker"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Questionanseditor editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/slideaieditor"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Slideaieditor editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/schoolworkqaadveditor/:sid"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Schoolworkqaadveditor editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/editschoolworkqaadveditor/:sid"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Schoolworkqaadveditor editing='true' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkqaeditor/:sid"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Schoolworkqaeditor editing='false' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/editschoolworkqaeditor/:sid"
              element={
                <React.Suspense fallback={<>loading portal...</>}>
                  <Schoolworkqaeditor editing='true' AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/myspeakingrecords/:mid"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <MySpeakingRecords AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/speakingrecords/:sbid"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SpeakingRecords AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/speakingManager"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SpeakingManager AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/botdeployment"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <BotDeployment AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            
            <Route
              path="/flashcardadaptiveviewer/:type/:sid"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Flashcardadaptiveviewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoolclassviewer" />
                </React.Suspense>
              }
            />
            <Route
              path="/flashcardadaptiveviewer"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Flashcardadaptiveviewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoolclassviewer" />
                </React.Suspense>
              }
            />
            <Route
              path="/performanceviewer/:type/:sid"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <PerformanceViewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoolclassviewer" />
                </React.Suspense>
              }
            />
            <Route
              path="/performanceviewer"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <PerformanceViewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoolclassviewer" />
                </React.Suspense>
              }
            />

            <Route
              path="/schoolclassviewer"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Schoolclassviewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoolclassviewer" />
                </React.Suspense>
              }
            />

            <Route
              path="/adminpanel"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <AdminPanel AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoolclassviewer" />
                </React.Suspense>
              }
            />
            <Route
              path="/distributionmanger"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <DistributionManger AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="DistributionManger" />
                </React.Suspense>
              }
            />
            <Route
              path="/markdistribution"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <DistributionManger AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} serviceType="distributionMarker" />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolmemberviewer"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolMemberViewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoomemberviewer" />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolmembermanager"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolMemberManager AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="SchoolMemberManager" />
                </React.Suspense>
              }
            />
            <Route
              path="/myassignments"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <MyAssignments AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/myflashcards"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <MyFlashCards AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />



            <Route
              path="/chemqaeditor"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Chemqaeditor AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/casestudygen"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Casestudygen AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/highresfilestraining"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Highresfilestraining AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/schoolworkgrader/:bid/:did/:botname"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkIngest AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkuploader"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkIngest AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoolworkmanager" />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkmanager"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkManager AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoolworkmanager" />
                </React.Suspense>
              }
            />
            <Route
              path="/flashcardmanager"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Flashcardmanager AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="schoolworkmanager" />
                </React.Suspense>
              }
            />


            <Route
              path="/mcmanager"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <MCManager AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="mcmanager" />
                </React.Suspense>
              }
            />
            <Route
              path="/myschoolwork"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <MySchoolWork AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkadvmarker/:bid/:did/:botname/ALL"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkAdvViewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} markFunction="AIMark" />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkadvreview/:bid/:did/:botname/:start"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolworkAdvReview AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} botName="cuhkschoolai" />
                </React.Suspense>
              }
            />


            <Route
              path="/schoolworkadvmarker/:bid/:did/:botname"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkReviewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} markFunction="AIMark" />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkadvmarker/:bid/:did/:botname/:start"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkAdvViewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} markFunction="AIMark" />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkadvviewer/:bid/:did/:botname/ALL"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkAdvViewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/schoolworkadvviewer/:bid/:did/:botname/:start"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkAdvViewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkviewer/:bid/:did/:botname"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkViewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/mcadvviewer/:bid/:did/:botname"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <MCAdvViewer AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/schoolworkreview/:bid/:botname"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <SchoolWorkSubmitted AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/invoice"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Invoice cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/conversation"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <ChatHistory cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/billing"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Billing cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/bottraining"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Training cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/botmedia"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Bot_media cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />
            <Route
              path="/report"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Report cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />



            <Route
              path="/signin"
              element={
                <React.Suspense fallback={<>loading signin...</>}>
                  <WagmiConfig client={client}>
                    <Signin />
                  </WagmiConfig>
                </React.Suspense>
              }
            />
            <Route
              path="/user"
              element={
                <React.Suspense fallback={<>loading user...</>}>
                  <WagmiConfig client={client}>
                    <User />
                  </WagmiConfig>
                </React.Suspense>
              }
            />
            <Route
              path="/training_info"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Training_info AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />


            {/*
          <Route 
            path="/schoolbotai/training_info" 
            element={
              <React.Suspense fallback={<>loading...</>}>
                <Training_info AppLogout={AppLogout} cookies={ cookies } isLoggedIn={ cookies.username != undefined } botName="schoolbotai"/>
              </React.Suspense>
            } 
          />

*/}

            <Route
              path="/training_lib"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Training_lib AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/training_study"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Training_study AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/training_conversation"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Training_conversation AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/training_roleplay"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <Training_roleplay AppLogout={AppLogout} cookies={cookies} isLoggedIn={cookies.username != undefined} />
                </React.Suspense>
              }
            />

            <Route
              path="/changepw"
              element={
                <React.Suspense fallback={<>loading page...</>}>

                  <div className="welcome">
                    <div className="cl_loginbar">
                      <button onClick={handleTopBarClick}><b><img className='cl_topbarimg' /><span>{cookies.username}</span></b></button>
                      <button className='cl_topbtn1' onClick={AppLogout}>{cookies.username != undefined ? 'Log out' : 'Sign in'}</button>
                    </div>
                    <Changepw />
                  </div>


                </React.Suspense>
              }
            />
            <Route
              path="/faqmaker"
              element={
                <React.Suspense fallback={<>loading page...</>}>

                  <div className="welcome">
                    <div className="cl_loginbar">
                      <button onClick={handleTopBarClick}><b><img className='cl_topbarimg' /><span>{cookies.username}</span></b></button>
                      <button className='cl_topbtn1' onClick={AppLogout}>{cookies.username != undefined ? 'Log out' : 'Sign in'}</button>
                    </div>
                    <FAQMaker />
                  </div>


                </React.Suspense>
              }
            />

            <Route
              path="/exercisemaker"
              element={
                <React.Suspense fallback={<>loading page...</>}>

                  <div className="welcome">
                    <div className="cl_loginbar">
                      <button onClick={handleTopBarClick}><b><img className='cl_topbarimg' /><span>{cookies.username}</span></b></button>
                      <button className='cl_topbtn1' onClick={AppLogout}>{cookies.username != undefined ? 'Log out' : 'Sign in'}</button>
                    </div>
                    <Exercisemaker />
                  </div>


                </React.Suspense>
              }
            />


            <Route path="/signup" element={<Register AppLogin={AppLogin} error={error} redirect={true} />} />


            <Route path="/forgotpw"
              element={
                <React.Suspense fallback={<>loading forgot pw...</>}>
                  <Forgotpw />
                  <div className={s.cl_footer}>
                    <div className={s.cl_centerlocator}> © 2023 SimProBot. All rights reserved</div>
                  </div>
                </React.Suspense>
              }
            />
            <Route path="/getdbdata"
              element={
                <React.Suspense fallback={<>loading Get DB...</>}>
                  <Getdbdata />
                </React.Suspense>
              }
            />


            <Route path="/accountregistration"
              element={
                <React.Suspense fallback={<>loading account registration...</>}>
                  <Accountregistration />
                </React.Suspense>
              }
            />

            <Route
              path="/schoolbotai/login"
              element={
                <React.Suspense fallback={<>loading login...</>}>

                  <div className="welcome">
                    <Login AppLogin={AppLogin} error={error} isLoggedIn={(cookies.username != undefined)} cookies={cookies} />
                  </div>
                  <div className={s.cl_footer}>
                    <div className={s.cl_footer}>
                      <table className={s.cl_table1}>
                        <tr>
                          <td className={s.cl_td2} style={{ textAlign: "left" }}>
                            <a className={s.cl_footerlink1} target="_blank" href="https://discord.com/channels/1012710499961552916/1012710499961552919">Discord</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://twitter.com/linked3app">Twitter</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.instagram.com/linked3app/">Instagram</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.facebook.com/linked3app">Facebook</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://linked3.medium.com/">Medium</a>
                            <a className={s.cl_footerlink1} target="_blank" href="mailto:contact@linked3.xyz">Email</a>
                          </td>
                          <td className={s.cl_td2} style={{ textAlign: "right" }}>
                            <span className={s.cl_centerlocator}> © 2023 Linked3. All rights reserved</span>
                          </td>

                        </tr>
                      </table>



                    </div>
                  </div>

                </React.Suspense>
              }
            />

            <Route
              path="/myspeaking"
              element={
                <React.Suspense fallback={<>loading login...</>}>

                  <div className="welcome">
                    <Login AppLogin={AppLogin} error={error} isLoggedIn={(cookies.username != undefined)} cookies={cookies} />
                  </div>
                  <div className={s.cl_footer}>
                    <div className={s.cl_footer}>
                      <table className={s.cl_table1}>
                        <tr>
                          <td className={s.cl_td2} style={{ textAlign: "left" }}>
                            <a className={s.cl_footerlink1} target="_blank" href="https://discord.com/channels/1012710499961552916/1012710499961552919">Discord</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://twitter.com/linked3app">Twitter</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.instagram.com/linked3app/">Instagram</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.facebook.com/linked3app">Facebook</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://linked3.medium.com/">Medium</a>
                            <a className={s.cl_footerlink1} target="_blank" href="mailto:contact@linked3.xyz">Email</a>
                          </td>
                          <td className={s.cl_td2} style={{ textAlign: "right" }}>
                            <span className={s.cl_centerlocator}> © 2023 Linked3. All rights reserved</span>
                          </td>

                        </tr>
                      </table>



                    </div>
                  </div>

                </React.Suspense>
              }
            />


            <Route
              path="/login"
              element={
                <React.Suspense fallback={<>loading login...</>}>

                  <div className="welcome">
                    <Login AppLogin={AppLogin} error={error} isLoggedIn={(cookies.username != undefined)} cookies={cookies} />
                  </div>
                  <div className={s.cl_footer}>
                    <div className={s.cl_footer}>
                      <table className={s.cl_table1}>
                        <tr>
                          <td className={s.cl_td2} style={{ textAlign: "left" }}>
                            <a className={s.cl_footerlink1} target="_blank" href="https://discord.com/channels/1012710499961552916/1012710499961552919">Discord</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://twitter.com/linked3app">Twitter</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.instagram.com/linked3app/">Instagram</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.facebook.com/linked3app">Facebook</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://linked3.medium.com/">Medium</a>
                            <a className={s.cl_footerlink1} target="_blank" href="mailto:contact@linked3.xyz">Email</a>
                          </td>
                          <td className={s.cl_td2} style={{ textAlign: "right" }}>
                            <span className={s.cl_centerlocator}> © 2023 Linked3. All rights reserved</span>
                          </td>

                        </tr>
                      </table>



                    </div>
                  </div>

                </React.Suspense>
              }
            />

            <Route
              path="/login/student"
              element={
                <React.Suspense fallback={<>loading login...</>}>

                  <div className="welcome">
                    <Login AppLogin={AppLogin} error={error} isLoggedIn={(cookies.username != undefined)} cookies={cookies} botName="schoolbotai" />
                  </div>
                  <div className={s.cl_footer}>
                    <div className={s.cl_footer}>
                      <table className={s.cl_table1}>
                        <tr>
                          <td className={s.cl_td2} style={{ textAlign: "left" }}>
                            <a className={s.cl_footerlink1} target="_blank" href="https://discord.com/channels/1012710499961552916/1012710499961552919">Discord</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://twitter.com/linked3app">Twitter</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.instagram.com/linked3app/">Instagram</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.facebook.com/linked3app">Facebook</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://linked3.medium.com/">Medium</a>
                            <a className={s.cl_footerlink1} target="_blank" href="mailto:contact@linked3.xyz">Email</a>
                          </td>
                          <td className={s.cl_td2} style={{ textAlign: "right" }}>
                            <span className={s.cl_centerlocator}> © 2023 Linked3. All rights reserved</span>
                          </td>

                        </tr>
                      </table>



                    </div>
                  </div>

                </React.Suspense>
              }
            />


            path="/bot/:memberid_from_url/:botid_from_url/:version_from_url"

            {
              /*
                        <Route
                          path="/register" 
                          element={
                            <React.Suspense fallback={<>loading register...</>}>
                              <div className="cl_loginbar"  style={{height: "30px"}}>
                                <select id="localesDropDown" className={s.cl_localesdropdown} onChange={onChangeLanguage} defaultValue={en_US}>
                                    {
                                        localeList.map((locale,index)=>(
                                          <option key={index} value={locale.code}>{locale.name}</option>
                                        ))
                                    }
                                </select>
                              </div>
                              <Register AppLogin={AppLogin} error={error} redirect={true}/>
              
                            <div className={s.cl_footer}>
                               <table className={s.cl_table1}>
                              <tr>
                              
                              <td className={s.cl_td2} style={{textAlign:"right"}}>
                               <span className={s.cl_centerlocator}> © 2023 SimProBot. All rights reserved</span>
                              </td>
                           
                              </tr>
                              </table>   
              
              
                                
                              </div>
              
                            </React.Suspense>
                           } 
                         />
              
              */
            }
            <Route
              path="/cuhk"
              element={
                <React.Suspense fallback={<>loading login...</>}>

                  <div className="welcome">
                    <Login AppLogin={AppLogin} error={error} isLoggedIn={(cookies.username != undefined)} cookies={cookies} botName="cuhkschoolbotai" />
                  </div>
                  <div className={s.cl_footer}>
                    <div className={s.cl_footer}>
                      <table className={s.cl_table1}>
                        <tr>
                          <td className={s.cl_td2} style={{ textAlign: "left" }}>
                            <a className={s.cl_footerlink1} target="_blank" href="https://discord.com/channels/1012710499961552916/1012710499961552919">Discord</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://twitter.com/linked3app">Twitter</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.instagram.com/linked3app/">Instagram</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://www.facebook.com/linked3app">Facebook</a>
                            <a className={s.cl_footerlink1} target="_blank" href="https://linked3.medium.com/">Medium</a>
                            <a className={s.cl_footerlink1} target="_blank" href="mailto:contact@linked3.xyz">Email</a>
                          </td>
                          <td className={s.cl_td2} style={{ textAlign: "right" }}>
                            <span className={s.cl_centerlocator}> © 2023 Linked3. All rights reserved</span>
                          </td>

                        </tr>
                      </table>



                    </div>
                  </div>

                </React.Suspense>
              }
            />


            path="/bot/:memberid_from_url/:botid_from_url/:version_from_url"

            {
              /*
                        <Route
                          path="/register" 
                          element={
                            <React.Suspense fallback={<>loading register...</>}>
                              <div className="cl_loginbar"  style={{height: "30px"}}>
                                <select id="localesDropDown" className={s.cl_localesdropdown} onChange={onChangeLanguage} defaultValue={en_US}>
                                    {
                                        localeList.map((locale,index)=>(
                                          <option key={index} value={locale.code}>{locale.name}</option>
                                        ))
                                    }
                                </select>
                              </div>
                              <Register AppLogin={AppLogin} error={error} redirect={true}/>
              
                            <div className={s.cl_footer}>
                               <table className={s.cl_table1}>
                              <tr>
                              
                              <td className={s.cl_td2} style={{textAlign:"right"}}>
                               <span className={s.cl_centerlocator}> © 2023 SimProBot. All rights reserved</span>
                              </td>
                           
                              </tr>
                              </table>   
              
              
                                
                              </div>
              
                            </React.Suspense>
                           } 
                         />
              
              */
            }

            <Route
              path="/resetpw/:id"
              element={
                <React.Suspense fallback={<>loading resetpw...</>}>
                  <Resetpw />
                  <div className={s.cl_footer}>
                    <div className={s.cl_centerlocator}> © 2023 SimProBot. All rights reserved</div>
                  </div>
                </React.Suspense>
              }
            />

            <Route
              path="*"
              element={
                <React.Suspense fallback={<></>}>
                  <ErrorPage />
                </React.Suspense>

              }
            />

          </Routes>

          <select id="localesDropDown" className="hidden fixed bottom-0 right-2 bg-white  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={onChangeLanguage} defaultValue={en_US}>
            {
              localeList.map((locale, index) => (
                <option key={index} value={locale.code}>{locale.name}</option>
              ))
            }
          </select>
        </BrowserRouter>
      </div>
    </IntlProvider>
  );
}

export default App;